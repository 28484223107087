<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <!-- 头部面包屑 -->
      <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">运营工具</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">轮播图列表</a>
                </span>
      </div>
      <!-- 主体区域 -->
      <div class="framePage-body">
        <!-- 搜索区域 -->
        <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
          <div class="searchbox">
            <div title="轮播图名称" class="searchboxItem ci-full">
              <span class="itemLabel">轮播图名称:</span>
              <el-input v-model="searchForm.bannerName" placeholder="请输入轮播图名称" type="text" clearable
                        size="small"/>
            </div>
            <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel">创建日期:</span>
              <el-date-picker
                  clearable
                  size="small"
                  v-model="searchForm.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select
                  v-model="searchForm.enabled"
                  placeholder="请选择状态"
                  size="small"
                  clearable
              >
                <el-option label="启用" :value="true"></el-option>
                <el-option label="停用" :value="false"></el-option>
              </el-select>
            </div>
            <div class="searchboxItem">
              <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="newlyAdded(null)">新增</el-button>
            </div>
          </div>
        </div>

        <!-- 列表区域 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="listData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  width="50"
              />
              <el-table-column
                  label="轮播图名称"
                  prop="bannerName"
                  min-width="220px"
                  align="center"
              />
              <el-table-column
                  label="图片"
                  prop="bannerKeyUrl"
                  min-width="100px"
                  align="center"
              >
                <template v-slot="{row}">
                  <el-image :src="row.bannerKeyUrl"
                  >
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                  label="状态"
                  prop="enabled"
                  min-width="130px"
                  align="center"
              >
                <template v-slot="{row}">
                  <el-switch
                      :width="20"
                      v-model="row.enabled"
                      :active-value="true"
                      :inactive-value="false"
                      active-color="#13ce66"
                      @change="changeBannerState(row)"
                  ></el-switch>
                  <span>{{ row.enabled ? "启用" : "停用" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="顺序"
                  min-width="150px"
                  prop="sortId"
                  align="center"/>
              <el-table-column
                  label="创建时间"
                  prop="createTime"
                  align="center"
              />
              <el-table-column
                  label="操作"
                  width="300px"
                  fixed="right"
                  align="center"
              >
                <template v-slot="{row}">
                  <el-button type="text" size="mini" style="padding: 0 5px;" @click="newlyAdded(row)">编辑</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px;" @click="deleteTraining(row)">删除
                  </el-button>
                </template>
              </el-table-column>
              <Empty slot="empty"/>
            </el-table>
          </div>
        </div>

        <!-- 分页器 -->
        <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
      </div>
    </div>

    <!-- 新增对话框 -->
    <el-dialog
        :title="dialogType===1?'新增轮播图':'编辑轮播图'"
        :visible.sync="dialogVisible"
        width="1000px"
        @closed="dialogClosed"
    >
      <el-form ref="dialogForm" :model="dialogForm" :rules="rules" size="small" label-width="100px">
        <el-form-item label="轮播图名称" prop="bannerName">
          <el-input v-model="dialogForm.bannerName"
                    placeholder="请输入轮播图名称"></el-input>
        </el-form-item>
        <el-form-item label="轮播图顺序" prop="sortId">
          <el-input-number
              style="width: 100%"
              size="small"
              :min="1"
              v-model="dialogForm.sortId"
          ></el-input-number>
        </el-form-item>
        <el-form-item
            label="轮播图"
            prop="bannerKey"
        >
          <el-upload
              :on-change="handlebusinessLicense2"
              :before-upload="$beforeAvatarUpload"
              :http-request="$requestMine"
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
                :src="dialogForm.bannerKeyUrl || require('@/assets/develop.png')"
                fit="contain"
                class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="跳转目标" prop="rotationType">
          <el-radio-group
              v-model="dialogForm.rotationType"
          >
            <el-radio :label="0">无跳转</el-radio>
            <el-radio :label="1">链接</el-radio>
            <el-radio :label="2">小程序</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转地址" prop="bannerPath" v-if="dialogForm.rotationType !== 0" >
          <el-input
              type="text"
              v-model="dialogForm.bannerPath"
              size="small"
              clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="customBtn" round @click="dialogClosed">取 消</el-button>
        <el-button class="customBtn" round type="primary" @click="submit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {resetKeepAlive} from "@/utils/common";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "bannerList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      // 搜索区域表单
      searchForm: {
        bannerName: null,
        createTime: [],
        enabled: null
      },
      //培训列表数据
      listData: [],
      // 分页器数据
      pagination: {
        total: 0,//总条数
        size: 10,//每页几条数据
        current: 1//当前页
      },
      // 对话框表单信息
      dialogForm: {
        bannerId: "",//id
        bannerName: "",//轮播图名称
        bannerKey: "",//轮播图key
        bannerKeyUrl: "",//轮播图key
        sortId: "",//排序
        rotationType: 0,//跳转目标
        bannerPath: "",//地址
      },
      //对话框可见性
      dialogVisible: false,
      // 对话框是添加还是编辑
      dialogType: 1, // 1-添加  2-编辑
      // 表单规则
      rules: {
        bannerName: [
          {required: true, message: "请输入轮播图名称", trigger: "blur"}
        ],
        sortId: [
          {required: true, message: "请输入轮播图顺序", trigger: "blur"}
        ],
        bannerKey: [
          {required: true, message: "请上传轮播图", trigger: "change"}
        ],
        bannerPath: [
          {required: true, message: "请输入跳转地址", trigger: "blur"},
          { trigger: "blur", max: 255, message: "跳转地址长度不能超过255个字符" },
        ]
      },
    }
  },
  methods: {
    // 序号
    indexMethod(index) {
      return (this.pagination.current - 1) * this.pagination.size + index + 1;
    },
    // 获取列表数据
    getListData(current) {
      const {searchForm, pagination} = this;
      if (current) this.pagination.current = current;
      let param = {
        pageNum: pagination.current,
        pageSize: pagination.size,
      };
      if (searchForm.bannerName) {
        param.bannerName = searchForm.bannerName.trim();
      }
      if (searchForm.createTime && searchForm.createTime.length === 2) {
        param.startDate = searchForm.createTime[0];
        param.endDate = searchForm.createTime[1];
      }
      param.enabled = searchForm.enabled;
      this.$post("/biz/hr_evaluate/psy-home-banner/pageList", param,
          3000,
          true,
          6).then(res => {
        this.listData = res?.data?.list || [];
        pagination.total = res?.data?.total || 0;
      }).catch(e => {
        console.error("获取列表数据出错", e);
      });
    },
    // 新增或者编辑
    newlyAdded(row) {
      if (!row) {
        // 新增
        this.dialogType = 1;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.initE();
        });
      } else {
        // 编辑
        this.dialogType = 2;
        // 数据回显
        this.echoData(row);
      }
    },
    // 编辑数据回显
    echoData(row) {
      const {dialogForm} = this;
      Object.keys(dialogForm).forEach(key => {
        dialogForm[key] = row[key];
      });
      this.dialogVisible = true;
    },
    // 删除
    deleteTraining(row) {
      const {bannerId} = row;
      this.$confirm('是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: "bgc-bv",
      }).then(() => {
        // 数据回显
        this.$post("/biz/hr_evaluate/psy-home-banner/delete", {
              bannerId
            },
            3000,
            true,
            6).then(res => {
          this.getListData();
          this.$message.success("删除成功");
        });
      });
    },
    // 对话框提交
    submit() {
      const {dialogForm, dialogType} = this;
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          if (dialogType === 1) {
            delete dialogForm.bannerId;
            // 新增
            this.$post("/biz/hr_evaluate/psy-home-banner/insert", dialogForm, 3000, true, 6).then(() => {
              this.getListData();
              this.$message.success("新增成功");
              this.dialogVisible = false;
            });
          } else {
            // 编辑
            this.$post("/biz/hr_evaluate/psy-home-banner/modify", dialogForm, 3000, true, 6).then(res => {
              this.getListData();
              this.$message.success("修改成功");
              this.dialogVisible = false;
            });
          }
        }
      });
    },
    // 对话框关闭，重置表单，清除校验结果
    dialogClosed() {
      this.dialogForm = this.$options.data().dialogForm;
      this.$refs.dialogForm.resetFields();
      this.dialogVisible = false;
    },
    // 当前页改变
    currentChange(current) {
      this.pagination.current = current;
      this.getListData();
    },
    // 每页条数改变
    sizeChange(size) {
      this.pagination.size = size;
      this.getListData();
    },
    // 设置表格高度
    getTableHeight(opDom = false, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) tHeight -= 40 + 0.675 * 16 + 1;
      if (page) tHeight -= 32;
      this.tableHeight = tHeight;
    },
    changeBannerState(row) {
      var updateEnabled = row.enabled
      row.enabled = !row.enabled
      console.log(updateEnabled)
      console.log(row.enabled)
      this.$post(
          "/biz/hr_evaluate/psy-home-banner/modify",
          {
            bannerId: row.bannerId,
            enabled: updateEnabled,
          },
          3000,
          false,
          6
      )
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: 'success'
              })
              row.enabled = !row.enabled
            }
          })
          .catch((err) => {
            return;
          });
    },
    handlebusinessLicense2(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
          file.name.lastIndexOf(".") + 1,
          file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "TEMP");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.dialogForm.bannerKeyUrl = ret.data.fileURL || "";
        that.dialogForm.bannerKey = ret.data.fileKey || "";
      });
    },
  },
  created() {
    // 获取列表数据
    this.getListData();
  },
  beforeRouteLeave: resetKeepAlive,
}
</script>
<style lang="less" scoped>
.Coursecontract {

  .el-radio {
    .el-radio__label {
      display: none;
    }

    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }

    .el-radio__inner:hover {
      border-color: #5c6be8;
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>
<style lang="less" scoped>
.templateList {
  .customBtn:nth-child(2) {
    background-color: #5C6BE8;
    border: none;

    &:hover {
      background-color: #6875ee;
    }
  }
}

.operationControl {
  .itemLabel {
    min-width: 6rem;
  }
}

.docsDialog .el-form-item {
  margin-bottom: 20px;
}
</style>
